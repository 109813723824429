.ant-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item {
    width: 384px;

    .ant-otp {
      // width: 100%;
      column-gap: 28px;
    
      .ant-otp-input {
        width: 40px;
        height: 40px;
      }
    }

    .ant-form-item-label {
      padding: 0 0 2px;
      label {
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .ant-form-item-required {
        &::before {
          color: #ca4e58 !important;
        }
      }
    }
    .ant-form-item-control {
      // input
      .ant-input-filled {
        background: #f1f3f7;
      }
      .ant-input-status-error {
        &:not(.ant-input-disabled) {
          background: #f1f3f7;
        }
      }
      //select
      .ant-select-filled {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            background: #f1f3f7;
          }
        }
      }
      .ant-select-status-error {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            background: #f1f3f7;
          }
        }
      }

      // data-pick
      .ant-picker-filled {
        background: #f1f3f7;
      }
      .ant-picker-status-error {
        &:not(.ant-picker-disabled) {
          .ant-picker-suffix {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

.ant-divider-horizontal {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-menu {
  &.ant-menu-light {
    &.ant-menu-root {
      &.ant-menu-inline {
        border-inline-end: 1px solid rgba(0, 0, 0, 0.10);
        height: 100%;
        
        .ant-menu-item {
          margin-inline: 0;
          margin-inline-end: 24px;
          width: calc(100% - 24px);
          height: 64px;
          .ant-menu-title-content {
            flex: none !important;
          }
        }
        .ant-menu-item-selected {
          background-color: #F1F3F7;
    
          svg {
            path {
              fill: #1A5AFF;
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }
}

.ant-typography {
  .ant-typography-copy {
    color: rgba(0, 0, 0, 0.25);
    &:hover {
      color: #1A5AFF;
    }
  }
}

.ant-collapse {
  .ant-collapse-item {
    border-top: 1px solid rgba(0, 0, 0, 0.10);

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    }

    .ant-collapse-header {
      .ant-collapse-header-text {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      gap: 4px;
      &:hover {
        a {
          color: #1A5AFF;
        }
      }
      .ant-dropdown-menu-title-content {
        &:hover {
          color: #1A5AFF;
        }
      }
    }
    
  }
}

.ant-btn-primary {
  box-shadow: none;
}